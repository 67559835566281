import React, { useEffect, useState } from 'react';
import DashboardCards from '../CRMcomponents/DashboardCards';
import DashboardCharts from '../CRMcomponents/DashboardCharts';
import Topbar from "../CRMcomponents/Topbar";
import axios from 'axios';
import '../CRMstyles/Dashboard.css';  // Ensure you have similar responsive CRMstyles here

const Dashboard = () => {
    const [userName, setUserName] = useState('');
    const [dashboardData, setDashboardData] = useState({
        lists: 0,
        publicLists: 0,
        privateLists: 0,
        subscribers: 0,
        campaigns: 0,
        finishedCampaigns: 0,
        draftCampaigns: 0,
        messagesSent: 0,
        campaignViews: 0,
        linkClicks: 0,
        newContacts: 0,
        unsubscribes: 0,
        bounces: 0
    });

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (user) {
            const parsedUser = JSON.parse(user);
            setUserName(parsedUser.name || 'Guest');
            fetchDashboardData(parsedUser.id);
        }
    }, []);

    const fetchDashboardData = async (userId) => {
        try {
            const response = await axios.get(`/server/crm_function/api/dashboard/${userId}`);
            const data = response.data;
            setDashboardData({
                lists: data.totalLists,
                publicLists: data.publicLists,
                privateLists: data.privateLists,
                subscribers: data.totalSubscribers,
                campaigns: data.totalCampaigns,
                finishedCampaigns: data.sentCampaigns,
                draftCampaigns: data.draftCampaigns,
                messagesSent: data.messagesSent,
                campaignViews: data.campaignViews,
                linkClicks: data.linkClicks,
                newContacts: data.newContacts || 0,
                unsubscribes: data.unsubscribes || 0,
                bounces: data.bounces || 0
            });
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        }
    };

    return (
        <div className="dashboard">
            <div className="sidebar-header p-3">
                <h3>Welcome, {userName}!</h3>
            </div>
         <div className="main-content">
                <h1>Dashboard</h1>
                <div className="dashboard-cards">
                    <DashboardCards
                        lists={dashboardData.lists}
                        publicLists={dashboardData.publicLists}
                        privateLists={dashboardData.privateLists}
                        subscribers={dashboardData.subscribers}
                        campaigns={dashboardData.campaigns}
                        finishedCampaigns={dashboardData.finishedCampaigns}
                        draftCampaigns={dashboardData.draftCampaigns}
                        messagesSent={dashboardData.messagesSent}
                    />
                </div>

                <DashboardCharts
                    campaignViews={dashboardData.campaignViews}
                    linkClicks={dashboardData.linkClicks}
                    newContacts={dashboardData.newContacts}
                    unsubscribes={dashboardData.unsubscribes}
                    bounces={dashboardData.bounces}
                    totalSubscribers={dashboardData.subscribers}
                />
            </div>
        </div>
    );
};

export default Dashboard;
