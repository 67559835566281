import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const DashboardCharts = ({ campaignViews, linkClicks, newContacts = 0, unsubscribes = 0, bounces = 0, totalSubscribers = 0 }) => {
    const data = [
        { name: 'Aug 19 - Aug 24', TotalSubscribers: totalSubscribers, NewContacts: newContacts, Unsubscribes: unsubscribes, Bounces: bounces },
        { name: 'Aug 25 - Aug 30', TotalSubscribers: totalSubscribers, NewContacts: newContacts, Unsubscribes: unsubscribes, Bounces: bounces },
        { name: 'Sep 06 - Sep 11', TotalSubscribers: totalSubscribers, NewContacts: newContacts, Unsubscribes: unsubscribes, Bounces: bounces },
        { name: 'Sep 12 - Sep 17', TotalSubscribers: totalSubscribers, NewContacts: newContacts, Unsubscribes: unsubscribes, Bounces: bounces },
    ];

    return (
        <div className="dashboard-chart">
            <h2>Contacts - Last 30 days</h2>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="TotalSubscribers" fill="cadetblue" />
                    <Bar dataKey="NewContacts" fill="steelblue" />
                    <Bar dataKey="Unsubscribes" fill="#de4e7f" />
                    <Bar dataKey="Bounces" fill="orange" />
                </BarChart>
            </ResponsiveContainer>

            {/* Display totals under the chart */}
            <div className="chart-summary mt-4">
                <h4>Summary:</h4>
                <p><strong>Total Subscribers:</strong> {totalSubscribers}</p>
                <p><strong>New Contacts:</strong> {newContacts}</p>
                <p><strong>Unsubscribes:</strong> {unsubscribes}</p>
                <p><strong>Bounces:</strong> {bounces}</p>
            </div>
        </div>
    );
};

export default DashboardCharts;
