import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../CRMstyles/Topbar.css'; // Your custom CRMstyles
import logo from '../logo.png'; // Assuming your logo is saved in assets folder

const Topbar = () => {
    const [expanded, setExpanded] = useState(false); // Manage expanded state
    const navigate = useNavigate(); // For navigation

    // Handle navigation and collapse the navbar
    const handleNavigation = (path) => {
        navigate(path);
        setExpanded(false); // Collapse the navbar
    };

    return (
        <Navbar
            className="topbar"
            expand="lg"
            expanded={expanded} // Control whether the navbar is expanded or collapsed
        >
            {/* Replace text with your logo */}
            <Navbar.Brand onClick={() => handleNavigation('/')} style={{ cursor: 'pointer' }}>
                <img
                    src={logo} // Path to your logo
                    alt="Logo"
                    style={{ width: '50px' }} // Adjust the width of the logo as needed
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link onClick={() => handleNavigation('/app/dashboard')}>Dashboard</Nav.Link>
                    <Nav.Link onClick={() => handleNavigation('/app/campaigns')}>Campaigns</Nav.Link>
                    <Nav.Link onClick={() => handleNavigation('/app/lists')}>Lists</Nav.Link>
                    <Nav.Link onClick={() => handleNavigation('/app/subscribers')}>Subscribers</Nav.Link>
                    <Nav.Link onClick={() => handleNavigation('/app/signup')}>Sign Up</Nav.Link>
                    <Nav.Link onClick={() => handleNavigation('/app/signin')}>Sign In</Nav.Link>
                    <Nav.Link onClick={() => handleNavigation('/app/settings')}>Settings</Nav.Link>
                    <Nav.Link onClick={() => handleNavigation('/app/logout')}>Log Out</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Topbar;
