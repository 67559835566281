// src/CRMtemplates/advertisementTemplate.js

export const advertisementTemplate = `
<div>
    <div style="font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 10pt">
        <div style="font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 10pt">
            <div>
                <span class="font" style="font-family: 'trebuchet ms', arial, helvetica, sans-serif">
                    <span class="highlight" style="background-color:#FFFFFF">
                        <span class="colour" style="color:#717275">
                            <span class="font" style="font-family: 'Noto Sans JP', sans-serif">
                                <span class="size" style="font-size: 20px; font-weight: 300;">
                                 <p>  [highlightText]</p> 
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <br/>
            <br/>
            <div class="x_827423126align-center" style="text-align: center">
                <a href="https://www.clubhouselinks.com/"
                   style="display: inline-block; padding: 10px 20px; font-size: 16px; font-weight: bold; color: #FFFFFF; background: linear-gradient(45deg, #40E0D0, #20B2AA); border-radius: 5px; text-align: center; text-decoration: none"
                   target="_blank">Click Here to see my full Portfolio</a>
            </div>

            <!-- Section Text above each image -->
            
            <div style="margin-left: auto;margin-right: auto;">
            <div>
                <p>[section1text]</p>
                <img src="[image1]" alt="Ad Image 1" width="100%">
            </div>
            <div>
                <p>[section2text]</p>
                <img src="[image2]" alt="Ad Image 2" width="100%">
            </div>
            <div>
                <p>[section3text]</p>
                <img src="[image3]" alt="Ad Image 3" width="100%">
            </div>
            <div>
                <p>[section4text]</p>
                <img src="[image4]" alt="Ad Image 4" width="100%">
            </div>
            </div>

         
           
        </div>
    </div>
     <div class="text-center" style="text-align: center;  display: block;
    margin: 0 auto; padding-top: 140px">
                    <span class="font" style="font-family:'trebuchet ms', arial, helvetica, sans-serif; font-size: 8.33px; color: #000000;">
                    &nbsp;&nbsp;<p style=" margin-bottom: 0px"><b>Our first podcast is up detailing the power of A.I.</b></p>
                   <p style=";margin-bottom: 0px""> <b>&nbsp;You can listen to it by clicking the link below:</b></p>
                </span>
          
                <br/>
                <a href="https://open.spotify.com/episode/7KYNViI3HhSv4moUqhkTIJ?si=PWsatt_rSa-xtDsJ3mEuNA" target="_blank">
<img width="100px" src="https://res.cloudinary.com/drna15e8q/image/upload/v1728055642/SpotifyLinkPicture_pxjj2r.jpg" alt="Podcast Ad" style="
    display: block;
    margin: 0 auto;
">
                </a>
                <p>
                Evan Ligon
<br/>
CEO/Web Developer
<br/>
www.ClubhouseLinks.com
<br/>
Business: Evan.ligon@clubhouselinks.com
<br/>
Personal: Evanligon7@gmail.com
<br/>
(214) 548-9175

</p>

            </div>
</div>`;
