import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const UnsubscribePage = () => {
    const { subscriberId } = useParams();  // Get the subscriber ID from the route
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = async () => {
            try {
                // Make the request to unsubscribe the user
                const response = await axios.get(`/server/crm_function/unsubscribe/${subscriberId}`);
                if (response.status === 200) {
                    // Successfully unsubscribed, redirect to a confirmation page
                    navigate('/unsubscribe-success');
                } else {
                    setError('Failed to unsubscribe.');
                }
            } catch (err) {
                console.error('Error unsubscribing:', err);
                setError('An error occurred while unsubscribing.');
            } finally {
                setIsLoading(false);
            }
        };

        unsubscribe();
    }, [subscriberId, navigate]);

    return (
        <div className="unsubscribe-page">
            {isLoading ? (
                <p>Processing your request...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <p>You have been successfully unsubscribed.</p>
            )}
        </div>
    );
};

export default UnsubscribePage;
