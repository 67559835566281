import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const SubscribersPage = () => {
    const [subscribers, setSubscribers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSubscribers, setFilteredSubscribers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedSubscriber, setSelectedSubscriber] = useState(null);
    const [email, setEmail] = useState('');
    const [listIds, setListIds] = useState([]);  // Track selected lists
    const [allLists, setAllLists] = useState([]);
    const [tags, setTags] = useState('');  // Track subscriber tags
    const [opens, setOpens] = useState(0);  // Email opens count
    const [clicks, setClicks] = useState(0);  // Email clicks count
    const navigate = useNavigate();

    useEffect(() => {
        fetchSubscribers();
    }, []);

    const fetchSubscribers = async () => {
        setLoading(true);
        try {
            const user = JSON.parse(localStorage.getItem('user'));  // Retrieve user info
            if (!user || !user.id) {
                console.error('User ID not found');
                return;
            }

            const response = await fetch(`/server/crm_function/api/subscribers/user/${user.id}`);  // Fetch from backend
            const data = await response.json();

            if (response.ok) {
                setSubscribers(data);
                setFilteredSubscribers(data);  // Initially show all subscribers
            } else {
                console.error('Error fetching subscribers:', data.message);
            }
        } catch (error) {
            console.error('Error fetching subscribers:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch lists for editing list subscription
    const fetchLists = async () => {
        try {
            const response = await fetch('/server/crm_function/api/lists');
            const data = await response.json();
            setAllLists(data);
        } catch (error) {
            console.error('Error fetching lists:', error);
        }
    };

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = subscribers.filter(sub => sub.email.toLowerCase().includes(query));
        setFilteredSubscribers(filtered);
    };

    const handleDelete = async (id) => {
        const user = JSON.parse(localStorage.getItem('user'));  // Retrieve user info
        if (!user || !user.id) {
            console.error('User ID not found');
            return;
        }

        try {
            const response = await fetch(`/server/crm_function/api/subscribers/${id}?userId=${user.id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                fetchSubscribers();  // Refresh the list after deletion
            } else {
                console.error('Error deleting subscriber');
            }
        } catch (error) {
            console.error('Error deleting subscriber:', error);
        }
    };

    // Open the modal and set the selected subscriber's data
    const handleViewClick = async (subscriber) => {
        setSelectedSubscriber(subscriber);
        setEmail(subscriber.email);
        setTags(subscriber.tags ? subscriber.tags.join(',') : '');
        setOpens(subscriber.opens || 0);
        setClicks(subscriber.clicks || 0);

        // Fetch the lists associated with this subscriber
        try {
            const response = await fetch(`/server/crm_function/api/subscribers/${subscriber.id}/lists`);
            const data = await response.json();
            const selectedLists = data.map(list => list.id);  // Get the list IDs only

            setListIds(selectedLists);  // Set the selected list IDs for the modal
        } catch (error) {
            console.error('Error fetching subscriber lists:', error);
        }

        fetchLists();  // Fetch all available lists
        setShowModal(true);
    };

    // Save the changes made in the modal
    const handleSave = async () => {
        try {
            const updatedSubscriber = {
                email,
                lists: listIds,  // Lists selected in the modal
                tags: tags.split(',').map(tag => tag.trim()),  // Convert comma-separated tags into an array
            };

            // Ensure we are updating the subscriber by ID
            const response = await fetch(`/server/crm_function/api/subscribers/${selectedSubscriber.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedSubscriber),  // Send updated data
            });

            if (!response.ok) {
                throw new Error('Failed to update subscriber');
            }

            setShowModal(false);  // Close the modal after successful save
            fetchSubscribers();  // Refresh the subscribers list
        } catch (error) {
            console.error('Error updating subscriber:', error);
        }
    };

    // Handle multi-select change for lists
    const handleListChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions);
        const selectedListIds = selectedOptions.map((option) => option.value);
        setListIds(selectedListIds);
    };

    return (
        <div className="subscribers-page p-4">
            <Row className="mb-3">
                <Col>
                    <h2>Subscribers ({subscribers.length})</h2>
                </Col>
                <Col className="text-right">
                    <Button variant="primary" onClick={() => navigate('/subscribers/new')}>+ New</Button>
                </Col>
            </Row>

            <Form.Control
                type="text"
                placeholder="Search by email or name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="mb-3"
            />

            {loading ? (
                <p>Loading...</p>
            ) : (
                <Table striped bordered hover responsive>
                    <thead>
                    <tr>
                        <th>Status</th>
                        <th>E-mail</th>
                        <th>Name</th>
                        <th>Lists</th>
                        <th>Created</th>
                        <th>Updated</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredSubscribers.map(sub => (
                        <tr key={sub.id}>
                            <td>{sub.status}</td>
                            <td>{sub.email}</td>
                            <td>{sub.name}</td>
                            <td>{sub.list_count || 0}</td>  {/* Use sub.list_count to show list count */}
                            <td>{new Date(sub.created_at).toLocaleDateString()}</td>
                            <td>{new Date(sub.updated_at).toLocaleDateString()}</td>
                            <td>
                                <Button variant="info" onClick={() => handleViewClick(sub)}>
                                    View
                                </Button>{' '}
                                <Button
                                    variant="danger"
                                    onClick={() => handleDelete(sub.id)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}

            {/* Modal for editing subscriber */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Subscriber</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        {/* Multi-select for lists */}
                        <Form.Group controlId="listSubscriptions" className="mt-3">
                            <Form.Label>Lists Subscribed To</Form.Label>
                            <Form.Control
                                as="select"
                                multiple
                                value={listIds}  // Highlight previously selected lists
                                onChange={handleListChange}
                            >
                                {allLists.map(list => (
                                    <option key={list.id} value={list.id}>
                                        {list.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        {/* Tags */}
                        <Form.Group controlId="tags" className="mt-3">
                            <Form.Label>Tags</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Add tags (comma separated)"
                                value={tags}
                                onChange={(e) => setTags(e.target.value)}
                            />
                        </Form.Group>

                        {/* Email Opens and Clicks */}
                        <Form.Group controlId="emailOpens" className="mt-3">
                            <Form.Label>Email Opens</Form.Label>
                            <Form.Control
                                type="number"
                                readOnly
                                value={opens}
                            />
                        </Form.Group>

                        <Form.Group controlId="emailClicks" className="mt-3">
                            <Form.Label>Email Clicks</Form.Label>
                            <Form.Control
                                type="number"
                                readOnly
                                value={clicks}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SubscribersPage;
