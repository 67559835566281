import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [showModal, setShowModal] = useState(true);  // Control modal visibility
    const navigate = useNavigate();

    const handleSignUp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/server/crm_function/api/auth/signup/', { email, password, name });
            const { user, token } = response.data;

            // Store user information and token in localStorage
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token);

            // Redirect to dashboard or campaigns page
            navigate('/app/dashboard');
        } catch (error) {
            console.error('Sign-up failed', error);
        }
    };

    const handleSignIn = () => {
        setShowModal(false);  // Hide modal
        navigate('/app/signin');  // Redirect to sign-in page
    };

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Sign Up</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSignUp}>
                    {/* Name Field */}
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter your name"
                            required
                        />
                    </Form.Group>

                    {/* Email Field */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter email"
                            required
                        />
                    </Form.Group>

                    {/* Password Field */}
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter password"
                            required
                        />
                    </Form.Group>

                    {/* Sign-up Button */}
                    <Button style={{backgroundColor:'steelblue'}} variant="primary" type="submit" className="w-100">
                        Sign Up
                    </Button>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <div className="text-center w-100">
                    <span>Have an account? </span>
                    <Button style={{color:'green'}} variant="link" onClick={handleSignIn}>
                        Sign in here
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default SignUp;
