import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col, Tabs, Tab } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';
import { advertisementTemplate } from '../CRMtemplates/advertisementTemplate'; // Import template
import '../CRMstyles/CampaignContent.css';

const CampaignCreate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { campaignData: initialCampaignData } = location.state || {};

    // Initialize state for campaign fields
    const [campaignData, setCampaignData] = useState({
        name: '',
        subject: '',
        fromAddress: 'noreply@user@yoursite.com',
        listIds: [],  // Array of list IDs
        template: 'advertisement',  // Default template
        messenger: 'email',
        tags: '',
        content: '',  // Content will be handled as an empty string initially
        urlSlug: '',
        metadata: {},
        sendLater: false,
        scheduledDate: new Date(),
        publishToArchive: false,
        userId: null, // Set userId once the user is authenticated
        ...initialCampaignData
    });

    // Initialize state for template content
    const [templateContent, setTemplateContent] = useState({
        highlightText: 'Enter your highlighted text here',
        image1: '',
        image2: '',
        image3: '',
        image4: '',
        section1text: 'Enter section 1 text here',
        section2text: 'Enter section 2 text here',
        section3text: 'Enter section 3 text here',
        section4text: 'Enter section 4 text here',
    });

    const [userLists, setUserLists] = useState([]);
    const [activeTab, setActiveTab] = useState("campaign");

    // Fetch user lists
    useEffect(() => {
        const fetchUserLists = async () => {
            try {
                const userId = JSON.parse(localStorage.getItem('user')).id;
                const response = await axios.get(`/server/crm_function/api/lists/user/${userId}`);
                setUserLists(response.data);
            } catch (error) {
                console.error('Error fetching lists:', error);
            }
        };
        fetchUserLists();
    }, []);

    // Handle input changes for campaign fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCampaignData({ ...campaignData, [name]: value });
    };

    // Handle input changes for template content
    const handleTemplateContentChange = (e) => {
        const { name, value } = e.target;
        setTemplateContent({ ...templateContent, [name]: value });
    };

    // Handle list selection
    const handleListChange = (e) => {
        const selectedListIds = Array.from(e.target.selectedOptions, option => option.value);
        setCampaignData({ ...campaignData, listIds: selectedListIds });
    };

    // Handle continue to the content tab
    const handleContinue = () => {
        setActiveTab('content');
    };

    // Handle file upload and update the image URL in the state
    const handleFileUpload = async (e, imageNum) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axios.post('/server/crm_function/api/upload', formData);
                const imageUrl = response.data.url;
                setTemplateContent(prevState => ({ ...prevState, [`image${imageNum}`]: imageUrl }));
            } catch (error) {
                console.error('Error uploading image:', error);
                alert('Failed to upload image.');
            }
        }
    };

    // Render the template content with user inputs
    const renderTemplateContent = () => {
        let htmlContent = advertisementTemplate
            .replace('[highlightText]', templateContent.highlightText || 'Enter your highlighted text here')
            .replace('[image1]', templateContent.image1 || 'https://plchldr.co/i/1000x800?&bg=4682B4&fc=ffffff&text=Image+1+Placeholder')
            .replace('[image2]', templateContent.image2 || 'https://plchldr.co/i/1000x800?&bg=4682B4&fc=ffffff&text=Image+2+Placeholder')
            .replace('[image3]', templateContent.image3 || 'https://plchldr.co/i/1000x800?&bg=4682B4&fc=ffffff&text=Image+3+Placeholder')
            .replace('[image4]', templateContent.image4 || 'https://plchldr.co/i/1000x800?&bg=4682B4&fc=ffffff&text=Image+4+Placeholder')
            .replace('[section1text]', templateContent.section1text || 'Enter section 1 text here')
            .replace('[section2text]', templateContent.section2text || 'Enter section 2 text here')
            .replace('[section3text]', templateContent.section3text || 'Enter section 3 text here')
            .replace('[section4text]', templateContent.section4text || 'Enter section 4 text here');

        return htmlContent;
    };

    // Handle form submission
    const handleCreateCampaign = async (e) => {
        e.preventDefault();
        try {
            const user = JSON.parse(localStorage.getItem('user')); // Make sure this is set in localStorage
            const status = campaignData.sendLater ? 'scheduled' : 'sent';  // Determine status
            const campaignToSubmit = {
                ...campaignData,
                userId: user.id,
                content: renderTemplateContent(),  // Render final template content
                status,
                scheduledDate: campaignData.sendLater ? campaignData.scheduledDate : null
            };

            const response = await axios.post('/server/crm_function/api/campaigns/create', campaignToSubmit);
            console.log('Campaign created successfully:', response.data);
            navigate('/app/campaigns'); // Redirect to campaigns list
        } catch (error) {
            console.error('Error creating campaign:', error);
            alert('Failed to create the campaign.');
        }
    };

    // Validate template content fields
    const isTemplateContentValid = () => {
        const { highlightText, image1, image2, image3, image4, section1text, section2text, section3text, section4text } = templateContent;
        return highlightText.trim() !== '' &&
            image1.trim() !== '' &&
            image2.trim() !== '' &&
            image3.trim() !== '' &&
            image4.trim() !== '' &&
            section1text.trim() !== '' &&
            section2text.trim() !== '' &&
            section3text.trim() !== '' &&
            section4text.trim() !== '';
    };

    return (
        <div className="campaign-create-container p-4">
            <Row>
                <Col md={12}>
                    <Tabs activeKey={activeTab} onSelect={setActiveTab} id="campaign-tabs" className="mb-3">
                        {/* Campaign Tab */}
                        <Tab eventKey="campaign" title="Campaign">
                            <h3>Create Campaign</h3>
                            <Form>
                                {/* Name */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Campaign Name"
                                        value={campaignData.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                {/* Subject */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="subject"
                                        placeholder="Campaign Subject"
                                        value={campaignData.subject}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                {/* From Address */}
                                <Form.Group className="mb-3">
                                    <Form.Label>From Address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="fromAddress"
                                        placeholder="noreply@user@yoursite.com"
                                        value={campaignData.fromAddress}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                {/* Lists */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Lists</Form.Label>
                                    <Form.Select
                                        name="listIds"
                                        multiple
                                        value={campaignData.listIds}
                                        onChange={handleListChange}
                                    >
                                        {userLists.map(list => (
                                            <option key={list.id} value={list.id}>
                                                {list.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                {/* Continue Button */}
                                <Button type="button" variant="primary" onClick={handleContinue}>
                                    Continue
                                </Button>
                            </Form>
                        </Tab>

                        {/* Content Tab */}
                        <Tab eventKey="content" title="Content">
                            <Form>
                                {/* Editable Fields for Template */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Highlight Text</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="highlightText"
                                        placeholder="Enter highlight text"
                                        value={templateContent.highlightText}
                                        onChange={handleTemplateContentChange}
                                        rows={4}
                                    />
                                </Form.Group>

                                {/* New Section Text Fields */}
                                {[1, 2, 3, 4].map(num => (
                                    <Form.Group key={num} className="mb-3">
                                        <Form.Label>Section {num} Text</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name={`section${num}text`}
                                            placeholder={`Enter section ${num} text`}
                                            value={templateContent[`section${num}text`]}
                                            onChange={handleTemplateContentChange}
                                            rows={3}
                                        />
                                    </Form.Group>
                                ))}

                                {/* Image Fields */}
                                {[1, 2, 3, 4].map(num => (
                                    <Form.Group key={num} className="mb-3">
                                        <Form.Label>Image {num}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`image${num}`}
                                            placeholder={`Enter image URL for image ${num} or upload below`}
                                            value={templateContent[`image${num}`]}
                                            onChange={handleTemplateContentChange}
                                        />
                                        <Form.Control
                                            type="file"
                                            name={`image${num}_upload`}
                                            onChange={(e) => handleFileUpload(e, num)}
                                            className="mt-2"
                                        />
                                    </Form.Group>
                                ))}

                                {/* Preview section */}
                                <h4>Template Preview</h4>
                                <div className="template-preview">
                                    <div dangerouslySetInnerHTML={{ __html: renderTemplateContent() }} />
                                </div>

                                {/* Send Later Toggle */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Send Later</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="send-later-switch"
                                        label={campaignData.sendLater ? 'On' : 'Off'}
                                        checked={campaignData.sendLater}
                                        onChange={() => setCampaignData({ ...campaignData, sendLater: !campaignData.sendLater })}
                                    />
                                </Form.Group>

                                {/* DateTimePicker for scheduling */}
                                {campaignData.sendLater && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>Pick a date and time</Form.Label>
                                        <DateTimePicker
                                            onChange={date => setCampaignData({ ...campaignData, scheduledDate: date })}
                                            value={campaignData.scheduledDate}
                                            minDate={new Date()}  // Can't schedule in the past
                                        />
                                    </Form.Group>
                                )}

                                {/* Create Campaign Button */}
                                <Button type="submit" variant="success" onClick={handleCreateCampaign} disabled={!isTemplateContentValid()}>
                                    Create Campaign
                                </Button>
                            </Form>
                        </Tab>

                        {/* Archive Tab */}
                        <Tab eventKey="archive" title="Archive">
                            <Form.Group className="mb-3">
                                <Form.Label>Publish to Archive</Form.Label>
                                <Form.Check
                                    type="switch"
                                    name="publishToArchive"
                                    label="Publish this campaign to archive"
                                    checked={campaignData.publishToArchive}
                                    onChange={() =>
                                        setCampaignData({
                                            ...campaignData,
                                            publishToArchive: !campaignData.publishToArchive
                                        })
                                    }
                                />
                            </Form.Group>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </div>
    );
};

export default CampaignCreate;
