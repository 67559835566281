// MyPage.js
import React, { useEffect } from 'react';
import newImage from './images/members/portrait-young-handsome-businessman-wearing-suit-standing-with-crossed-arms-with-isolated-studio-white-background.jpg';
import newImage2 from './images/members/successful-asian-lady-boss-red-blazer-holding-clipboard-with-documens-pen-working-looking-happy-white-background.jpg';
import  newImage4 from'./images/anna-rosar-ew-olGvgCCs-unsplash.jpg'
import  newImage5 from     './images/anna-rosar-ZxFyVBHMK-c-unsplash.jpg'
import  newImage6 from './images/frederik-rosar-NDSZcCfnsbY-unsplash.jpg'
import newImage7 from './images/professional-golf-player.jpg'
import newImage8 from './images/girl-taking-selfie-with-friends-golf-field.jpg'
import newImage9 from './images/logo.png'





const SamplePortfolios3 = () => {
    useEffect(() => {
        const stylesheets = [
            './assets/css/templatemo-tiya-golf-club.css',
        ];

        // Create and append link elements
        stylesheets.forEach((href) => {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = href;
            document.head.appendChild(link);
        });

        // Clean up: remove stylesheets on component unmount
        return () => {
            stylesheets.forEach((href) => {
                const links = document.querySelectorAll(`link[href="${href}"]`);
                links.forEach((link) => link.parentNode.removeChild(link));
            });
        };
    }, []);


    return (
        <div>
       <body>
       <main>

           <nav className="navbar navbar-expand-lg">
               <div className="container">
                   <a className="navbar-brand d-flex align-items-center" href="index.html">
                       <img src={newImage7} className="navbar-brand-image img-fluid" alt="Tiya Golf Club"/>
                       <span className="navbar-brand-text">
              Tiya
              <small>Golf Club</small>
            </span>
                   </a>

                   <div className="d-lg-none ms-auto me-3">
                       <a className="btn custom-btn custom-border-btn" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">Member Login</a>
                   </div>

                   <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                       <span className="navbar-toggler-icon"></span>
                   </button>

                   <div className="collapse navbar-collapse" id="navbarNav">
                       <ul className="navbar-nav ms-lg-auto">
                           <li className="nav-item">
                               <a className="nav-link click-scroll" href="#section_1">Home</a>
                           </li>
                           <li className="nav-item">
                               <a className="nav-link click-scroll" href="#section_2">About</a>
                           </li>
                           <li className="nav-item">
                               <a className="nav-link click-scroll" href="#section_3">Membership</a>
                           </li>
                           <li className="nav-item">
                               <a className="nav-link click-scroll" href="#section_4">Events</a>
                           </li>
                           <li className="nav-item">
                               <a className="nav-link click-scroll" href="#section_5">Contact Us</a>
                           </li>
                           <li className="nav-item dropdown">
                               <a className="nav-link dropdown-toggle" href="#" id="navbarLightDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">Pages</a>
                               <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarLightDropdownMenuLink">
                                   <li><a className="dropdown-item" href="event-listing.html">Event Listing</a></li>
                                   <li><a className="dropdown-item" href="event-detail.html">Event Detail</a></li>
                               </ul>
                           </li>
                       </ul>

                       <div className="d-none d-lg-block ms-lg-3">
                           <a className="btn custom-btn custom-border-btn" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">Member Login</a>
                       </div>
                   </div>
               </div>
           </nav>

           <div className="offcanvas offcanvas-end" data-bs-scroll="true" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
               <div className="offcanvas-header">
                   <h5 className="offcanvas-title" id="offcanvasExampleLabel">Member Login</h5>
                   <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
               </div>

               <div className="offcanvas-body d-flex flex-column">
                   <form className="custom-form member-login-form" action="#" method="post" role="form">
                       <div className="member-login-form-body">
                           <div className="mb-4">
                               <label className="form-label mb-2" htmlFor="member-login-number">Membership No.</label>
                               <input type="text" name="member-login-number" id="member-login-number" className="form-control" placeholder="11002560" required />
                           </div>
                           <div className="mb-4">
                               <label className="form-label mb-2" htmlFor="member-login-password">Password</label>
                               <input type="password" name="member-login-password" id="member-login-password" pattern="[0-9a-zA-Z]{4,10}" className="form-control" placeholder="Password" required />
                           </div>
                           <div className="form-check mb-4">
                               <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                               <label className="form-check-label" htmlFor="flexCheckDefault">
                                   Remember me
                               </label>
                           </div>
                           <div className="col-lg-5 col-md-7 col-8 mx-auto">
                               <button type="submit" className="form-control">Login</button>
                           </div>
                           <div className="text-center my-4">
                               <a href="#">Forgotten password?</a>
                           </div>
                       </div>
                   </form>

                   <div className="mt-auto mb-5">
                       <p>
                           <strong className="text-white me-3">Any Questions?</strong>
                           <a href="tel:010-020-0340" className="contact-link">
                               010-020-0340
                           </a>
                       </p>
                   </div>
               </div>

               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                   <path fill="#3D405B" fillOpacity="1" d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
               </svg>
           </div>

           <section className="hero-section d-flex justify-content-center align-items-center" id="section_1">
               <div className="section-overlay"></div>

               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                   <path fill="#3D405B" fillOpacity="1" d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
               </svg>

               <div className="container">
                   <div className="row">
                       <div className="col-lg-6 col-12 mb-5 mb-lg-0">
                           <h2 className="text-white">Welcome to the club</h2>
                           <h1 className="cd-headline rotate-1 text-white mb-4 pb-2">
                               <span>Tiya is</span>
                               <span className="cd-words-wrapper">
                <b className="is-visible">Modern</b>
                <b>Creative</b>
                <b>Lifestyle</b>
              </span>
                           </h1>
                           <div className="custom-btn-group">
                               <a href="#section_2" className="btn custom-btn smoothscroll me-3">Our Story</a>
                               <a href="#section_3" className="link smoothscroll">Become a member</a>
                           </div>
                       </div>

                       <div className="col-lg-6 col-12">
                           <div className="ratio ratio-16x9">
                               <iframe
                                   width="560"
                                   height="315"
                                   src="https://www.youtube.com/embed/MGNgbNGOzh8"
                                   title="YouTube video player"
                                   frameBorder="0"
                                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                   allowFullScreen
                               ></iframe>
                           </div>
                       </div>
                   </div>
               </div>

               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                   <path fill="#ffffff" fillOpacity="1" d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
               </svg>
           </section>

           <section className="about-section section-padding" id="section_2">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12 col-12 text-center">
                           <h2 className="mb-lg-5 mb-4">About Tiya</h2>
                       </div>

                       <div className="col-lg-5 col-12 me-auto mb-4 mb-lg-0">
                           <h3 className="mb-3">Tiya Club History</h3>
                           <p><strong>Since 1984</strong>, Tiya is ranked #8 in the top 10 golf courses in the world. Tiya is Bootstrap 5 HTML CSS template for golf clubs. Anyone can modify and use this layout for commercial purposes.</p>
                           <p>Tiya Golf Club is 100% free CSS template provided by TemplateMo website. Please tell your friends about our website. Thank you for visiting.</p>
                       </div>

                       <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-0">
                           <div className="member-block">
                               <div className="member-block-image-wrap">
                                   <img
                                       src={newImage}
                                       className="member-block-image img-fluid"
                                       alt=""
                                   />
                                   <ul className="social-icon">
                                       <li className="social-icon-item">
                                           <a href="#" className="social-icon-link bi-twitter"></a>
                                       </li>
                                       <li className="social-icon-item">
                                           <a href="#" className="social-icon-link bi-whatsapp"></a>
                                       </li>
                                   </ul>
                               </div>
                               <div className="member-block-info d-flex align-items-center">
                                   <h4>Michael</h4>
                                   <p className="ms-auto">Founder</p>
                               </div>
                           </div>
                       </div>

                       <div className="col-lg-3 col-md-6 col-12">
                           <div className="member-block">
                               <div className="member-block-image-wrap">
                                   <img
                                       src={newImage2}
                                       className="member-block-image img-fluid"
                                       alt=""
                                   />
                                   <ul className="social-icon">
                                       <li className="social-icon-item">
                                           <a href="#" className="social-icon-link bi-linkedin"></a>
                                       </li>
                                       <li className="social-icon-item">
                                           <a href="#" className="social-icon-link bi-twitter"></a>
                                       </li>
                                   </ul>
                               </div>
                               <div className="member-block-info d-flex align-items-center">
                                   <h4>Sandy</h4>
                                   <p className="ms-auto">Co-Founder</p>
                               </div>
                           </div>
                       </div>

                   </div>
               </div>
           </section>


           <section className="section-bg-image">
               <svg viewBox="0 0 1265 144" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                   <path fill="rgba(255, 255, 255, 1)" d="M 0 40 C 164 40 164 20 328 20 L 328 20 L 328 0 L 0 0 Z" strokeWidth="0"></path>
                   <path fill="rgba(255, 255, 255, 1)" d="M 327 20 C 445.5 20 445.5 89 564 89 L 564 89 L 564 0 L 327 0 Z" strokeWidth="0"></path>
                   <path fill="rgba(255, 255, 255, 1)" d="M 563 89 C 724.5 89 724.5 48 886 48 L 886 48 L 886 0 L 563 0 Z" strokeWidth="0"></path>
                   <path fill="rgba(255, 255, 255, 1)" d="M 885 48 C 1006.5 48 1006.5 67 1128 67 L 1128 67 L 1128 0 L 885 0 Z" strokeWidth="0"></path>
                   <path fill="rgba(255, 255, 255, 1)" d="M 1127 67 C 1196 67 1196 0 1265 0 L 1265 0 L 1265 0 L 1127 0 Z" strokeWidth="0"></path>
               </svg>

               <div className="container">
                   <div className="row">
                       <div className="col-lg-6 col-12">
                           <div className="section-bg-image-block">
                               <h2 className="mb-lg-3">Get our newsletter</h2>
                               <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor ut labore et dolore.</p>
                               <form action="#" method="get" className="custom-form mt-lg-4 mt-2" role="form">
                                   <div className="input-group input-group-lg">
                                       <span className="input-group-text bi-envelope" id="basic-addon1"></span>
                                       <input
                                           type="email"
                                           name="email"
                                           id="email"
                                           pattern="[^ @]*@[^ @]*"
                                           className="form-control"
                                           placeholder="Email address"
                                           required
                                       />
                                       <button type="submit" className="form-control">Subscribe</button>
                                   </div>
                               </form>
                           </div>
                       </div>
                   </div>
               </div>

               <svg viewBox="0 0 1265 144" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                   <path fill="rgba(255, 255, 255, 1)" d="M 0 40 C 164 40 164 20 328 20 L 328 20 L 328 0 L 0 0 Z" strokeWidth="0"></path>
                   <path fill="rgba(255, 255, 255, 1)" d="M 327 20 C 445.5 20 445.5 89 564 89 L 564 89 L 564 0 L 327 0 Z" strokeWidth="0"></path>
                   <path fill="rgba(255, 255, 255, 1)" d="M 563 89 C 724.5 89 724.5 48 886 48 L 886 48 L 886 0 L 563 0 Z" strokeWidth="0"></path>
                   <path fill="rgba(255, 255, 255, 1)" d="M 885 48 C 1006.5 48 1006.5 67 1128 67 L 1128 67 L 1128 0 L 885 0 Z" strokeWidth="0"></path>
                   <path fill="rgba(255, 255, 255, 1)" d="M 1127 67 C 1196 67 1196 0 1265 0 L 1265 0 L 1265 0 L 1127 0 Z" strokeWidth="0"></path>
               </svg>
           </section>


           <section class="membership-section section-padding" id="section_3">
               <div class="container">
                   <div class="row">

                       <div class="col-lg-12 col-12 text-center mx-auto mb-lg-5 mb-4">
                           <h2><span>Membership</span> at Tiya</h2>
                       </div>

                       <div class="col-lg-6 col-12 mb-3 mb-lg-0">
                           <h4 class="mb-4 pb-lg-2">Membership Fees</h4>

                           <div class="table-responsive">
                               <table class="table text-center">
                                   <thead>
                                   <tr>
                                       <th  style={{width:'34%'}}>Yearly Access</th>

                                       <th  style={{width:'22%'}}>T1 $420</th>

                                       <th  style={{width:'22%'}}>T2 $640</th>

                                       <th  style={{width:'22%'}}>T3 $860</th>
                                   </tr>
                                   </thead>

                                   <tbody>
                                   <tr>
                                       <th scope="row" class="text-start">Golf Insurance</th>

                                       <td>
                                           <i class="bi-check-circle-fill"></i>
                                       </td>

                                       <td>
                                           <i class="bi-check-circle-fill"></i>
                                       </td>

                                       <td>
                                           <i class="bi-check-circle-fill"></i>
                                       </td>
                                   </tr>

                                   <tr>
                                       <th scope="row" class="text-start">Club Facilities</th>

                                       <td>
                                           <i class="bi-check-circle-fill"></i>
                                       </td>

                                       <td>
                                           <i class="bi-check-circle-fill"></i>
                                       </td>

                                       <td>
                                           <i class="bi-check-circle-fill"></i>
                                       </td>
                                   </tr>

                                   <tr>
                                       <th scope="row" class="text-start">Country Club</th>

                                       <td>
                                           <i class="bi-x-circle-fill"></i>
                                       </td>

                                       <td>
                                           <i class="bi-check-circle-fill"></i>
                                       </td>

                                       <td>
                                           <i class="bi-check-circle-fill"></i>
                                       </td>
                                   </tr>

                                   <tr>
                                       <th scope="row" class="text-start">Weekend Seasonal</th>

                                       <td>
                                           <i class="bi-x-circle-fill"></i>
                                       </td>

                                       <td>
                                           <i class="bi-check-circle-fill"></i>
                                       </td>

                                       <td>
                                           <i class="bi-check-circle-fill"></i>
                                       </td>
                                   </tr>

                                   <tr>
                                       <th scope="row" class="text-start">Premium Courses</th>

                                       <td>
                                           <i class="bi-x-circle-fill"></i>
                                       </td>

                                       <td>
                                           <i class="bi-x-circle-fill"></i>
                                       </td>

                                       <td>
                                           <i class="bi-check-circle-fill"></i>
                                       </td>
                                   </tr>

                                   <tr>
                                       <th scope="row" class="text-start">Pro's Networking</th>

                                       <td>
                                           <i class="bi-x-circle-fill"></i>
                                       </td>

                                       <td>
                                           <i class="bi-x-circle-fill"></i>
                                       </td>

                                       <td>
                                           <i class="bi-check-circle-fill"></i>
                                       </td>
                                   </tr>

                                   </tbody>
                               </table>
                           </div>
                       </div>

                       <div class="col-lg-5 col-12 mx-auto">
                           <h4 class="mb-4 pb-lg-2">Please join us!</h4>
                           <form action="#" method="post" class="custom-form membership-form shadow-lg" role="form">
                               <h4 class="text-white mb-4">Become a member</h4>

                               <div class="form-floating">
                                   <input type="text" name="full-name" id="full-name" class="form-control" placeholder="Full Name" required=""/>

                                       <label for="floatingInput">Full Name</label>
                               </div>

                               <div class="form-floating">
                                   <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" class="form-control" placeholder="Email address" required=""/>

                                       <label for="floatingInput">Email address</label>
                               </div>

                               <div class="form-floating">
                                   <textarea class="form-control" id="message" name="message" placeholder="Describe message here"></textarea>

                                   <label for="floatingTextarea"> Comments</label>
                               </div>

                               <button type="submit" class="form-control">Submit</button>

                   </form>
               </div>

        </div>
</div>
</section>


    <section class="events-section section-bg section-padding" id="section_4">
        <div class="container">
            <div class="row">

                <div class="col-lg-12 col-12">
                    <h2 class="mb-lg-3">Upcoming Events</h2>
                </div>

                <div class="row custom-block mb-3">
                    <div class="col-lg-2 col-md-4 col-12 order-2 order-md-0 order-lg-0">
                        <div class="custom-block-date-wrap d-flex d-lg-block d-md-block align-items-center mt-3 mt-lg-0 mt-md-0">
                            <h6 class="custom-block-date mb-lg-1 mb-0 me-3 me-lg-0 me-md-0">24</h6>

                            <strong class="text-white">Feb 2048</strong>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-8 col-12 order-1 order-lg-0">
                        <div class="custom-block-image-wrap">
                            <a href="event-detail.html">
                                <img src={newImage7} class="custom-block-image img-fluid" alt=""/>

                                    <i class="custom-block-icon bi-link"></i>
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-12 order-3 order-lg-0">
                        <div class="custom-block-info mt-2 mt-lg-0">
                            <a href="event-detail.html" class="events-title mb-3">Private activities</a>

                            <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                            <div class="d-flex flex-wrap border-top mt-4 pt-3">

                                <div class="mb-4 mb-lg-0">
                                    <div class="d-flex flex-wrap align-items-center mb-1">
                                        <span class="custom-block-span">Location:</span>

                                        <p class="mb-0">National Center, NYC</p>
                                    </div>

                                    <div class="d-flex flex-wrap align-items-center">
                                        <span class="custom-block-span">Ticket:</span>

                                        <p class="mb-0">$250</p>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center ms-lg-auto">
                                    <a href="event-detail.html" class="btn custom-btn">Buy Ticket</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row custom-block custom-block-bg">
                    <div class="col-lg-2 col-md-4 col-12 order-2 order-md-0 order-lg-0">
                        <div class="custom-block-date-wrap d-flex d-lg-block d-md-block align-items-center mt-3 mt-lg-0 mt-md-0">
                            <h6 class="custom-block-date mb-lg-1 mb-0 me-3 me-lg-0 me-md-0">28</h6>

                            <strong class="text-white">Feb 2048</strong>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-8 col-12 order-1 order-lg-0">
                        <div class="custom-block-image-wrap">
                            <a href="event-detail.html">
                                <img src={newImage8} class="custom-block-image img-fluid" alt=""/>

                                    <i class="custom-block-icon bi-link"></i>
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-12 order-3 order-lg-0">
                        <div class="custom-block-info mt-2 mt-lg-0">
                            <a href="event-detail.html" class="events-title mb-3">Group tournament activities</a>

                            <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                            <div class="d-flex flex-wrap border-top mt-4 pt-3">

                                <div class="mb-4 mb-lg-0">
                                    <div class="d-flex flex-wrap align-items-center mb-1">
                                        <span class="custom-block-span">Location:</span>

                                        <p class="mb-0">National Center, NYC</p>
                                    </div>

                                    <div class="d-flex flex-wrap align-items-center">
                                        <span class="custom-block-span">Ticket:</span>

                                        <p class="mb-0">$350</p>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center ms-lg-auto">
                                    <a href="event-detail.html" class="btn custom-btn">Buy Ticket</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>


    <section class="contact-section section-padding" id="section_5">
        <div class="container">
            <div class="row">

                <div class="col-lg-5 col-12">
                    <form action="#" method="post" class="custom-form contact-form" role="form">
                        <h2 class="mb-4 pb-2">Contact Tiya</h2>

                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-floating">
                                    <input type="text" name="full-name" id="full-name" class="form-control" placeholder="Full Name" required=""/>

                                        <label for="floatingInput">Full Name</label>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-floating">
                                    <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" class="form-control" placeholder="Email address" required=""/>

                                        <label for="floatingInput">Email address</label>
                                </div>
                            </div>

                            <div class="col-lg-12 col-12">
                                <div class="form-floating">
                                    <textarea class="form-control" id="message" name="message" placeholder="Describe message here"></textarea>

                                    <label for="floatingTextarea">Message</label>
                                </div>

                                <button type="submit" class="form-control">Submit Form</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-lg-6 col-12">
                    <div class="contact-info mt-5">
                        <div class="contact-info-item">
                            <div class="contact-info-body">
                                <strong>London, United Kingdom</strong>

                                <p class="mt-2 mb-1">
                                    <a href="tel: 010-020-0340" class="contact-link">
                                        (020)
                                        010-020-0340
                                    </a>
                                </p>

                                <p class="mb-0">
                                    <a href="mailto:info@company.com" class="contact-link">
                                        info@company.com
                                    </a>
                                </p>
                            </div>

                            <div class="contact-info-footer">
                                <a href="#">Directions</a>
                            </div>
                        </div>

                        <img src="images/WorldMap.svg" class="img-fluid" alt=""/>
                    </div>
                </div>

            </div>
        </div>
    </section>
</main>

    <footer style={{backgroundColor:'white'}} class="site-footer">
        <div class="container">
            <div class="row">

                <div class="col-lg-6 col-12 me-auto mb-5 mb-lg-0">
                    <a class="navbar-brand d-flex align-items-center" href="index.html">
                        <img src={newImage9} class="navbar-brand-image img-fluid" alt=""/>
                            <span class="navbar-brand-text">
                                Tiya
                                <small>Golf Club</small>
                            </span>
                    </a>
                </div>

                <div class="col-lg-3 col-12">
                    <h5 class="site-footer-title mb-4">Join Us</h5>

                    <p class="d-flex border-bottom pb-3 mb-3 me-lg-3">
                        <span>Mon-Fri</span>
                        6:00 AM - 6:00 PM
                    </p>

                    <p class="d-flex me-lg-3">
                        <span>Sat-Sun</span>
                        6:30 AM - 8:30 PM
                    </p>
                    <br/>
                        <p class="copyright-text">Copyright © 2048 Tiya Golf Club</p>
                </div>

                <div class="col-lg-2 col-12 ms-auto">
                    <ul class="social-icon mt-lg-5 mt-3 mb-4">
                        <li class="social-icon-item">
                            <a href="#" class="social-icon-link bi-instagram"></a>
                        </li>

                        <li class="social-icon-item">
                            <a href="#" class="social-icon-link bi-twitter"></a>
                        </li>

                        <li class="social-icon-item">
                            <a href="#" class="social-icon-link bi-whatsapp"></a>
                        </li>
                    </ul>
                    <p class="copyright-text">Design: <a rel="nofollow" href="https://templatemo.com" target="_blank">TemplateMo</a></p>

                </div>

            </div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#81B29A" fill-opacity="1" d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
    </footer>
       </body>
        </div>
    );
};

export default SamplePortfolios3;
