import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';

const ListForm = ({ initialList, onSaveSuccess }) => {
    const [name, setName] = useState(initialList.name || '');
    const [type, setType] = useState(initialList.type || 'private');
    const [allSubscribers, setAllSubscribers] = useState([]);  // All subscribers
    const [selectedSubscriberIds, setSelectedSubscriberIds] = useState([]);  // Pre-selected subscribers

    useEffect(() => {
        const userId = JSON.parse(localStorage.getItem('user')).id;

        // Fetch all available subscribers
        fetch(`/server/crm_function/api/subscribers/user/${userId}`)
            .then(response => response.json())
            .then(data => setAllSubscribers(data))
            .catch(err => console.error('Error fetching subscribers:', err));

        // If editing a list, fetch the subscribers for the list
        if (initialList && initialList.id) {
            fetch(`/server/crm_function/api/lists/${initialList.id}/subscribers`)
                .then(response => response.json())
                .then(data => setSelectedSubscriberIds(data.map(sub => sub.id)))
                .catch(err => console.error('Error fetching list subscribers:', err));
        }
    }, [initialList]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const userId = JSON.parse(localStorage.getItem('user')).id;

        const listData = {
            name,
            type,
            user_id: userId,
            subscribers: selectedSubscriberIds,  // Send selected subscriber IDs
        };

        const method = initialList.id ? 'PUT' : 'POST';
        const endpoint = initialList.id
            ? `/server/crm_function/api/lists/${initialList.id}`
            : '/server/crm_function/api/lists/create';

        fetch(endpoint, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(listData),
        })
            .then(response => response.json())
            .then(() => onSaveSuccess())
            .catch(err => console.error('Error saving list:', err));
    };

    // Handle multi-select change for adding/removing subscribers
    const handleSubscriberChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions).map(option => parseInt(option.value));
        setSelectedSubscriberIds(selectedOptions);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="listName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter list name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group controlId="listType" className="mt-3">
                <Form.Label>Type</Form.Label>
                <Form.Control
                    as="select"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    required
                >
                    <option value="private">Private</option>
                    <option value="public">Public</option>
                </Form.Control>
            </Form.Group>

            {/* Multi-select for subscribers */}
            <Form.Group controlId="listSubscribers" className="mt-3">
                <Form.Label>Subscribers in this List</Form.Label>
                <Form.Control
                    as="select"
                    multiple
                    value={selectedSubscriberIds}  // Use selected subscriber IDs
                    onChange={handleSubscriberChange}
                >
                    {allSubscribers.map(subscriber => (
                        <option
                            key={subscriber.id}
                            value={subscriber.id}
                            selected={selectedSubscriberIds.includes(subscriber.id)}  // Pre-select if already in the list
                        >
                            {subscriber.name} ({subscriber.email})
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
                {initialList.id ? 'Update List' : 'Create List'}
            </Button>
        </Form>
    );
};

export default ListForm;
