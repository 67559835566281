// Success.js
import React from 'react';

const Success = () => (
    <div>
        <h1>Payment Successful!</h1>
    </div>
);

export default Success;




