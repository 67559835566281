import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';  // Added Alert component for error message
import { useNavigate, useParams } from 'react-router-dom';

const SubscribersForm = ({ initialSubscriber = {} }) => {
    const [email, setEmail] = useState(initialSubscriber.email || '');
    const [name, setName] = useState(initialSubscriber.name || '');
    const [status, setStatus] = useState(initialSubscriber.status || 'enabled');
    const [allLists, setAllLists] = useState([]);  // All available lists
    const [listIds, setListIds] = useState([]);  // Selected list IDs
    const [tags, setTags] = useState(initialSubscriber.tags ? initialSubscriber.tags.join(',') : '');  // Tags as comma-separated values
    const [error, setError] = useState('');  // State for error messages
    const { id } = useParams();  // Subscriber ID from the route (if editing)
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch all available lists
        fetch('/server/crm_function/api/lists')
            .then(response => response.json())
            .then(data => setAllLists(data))
            .catch(err => console.error('Error fetching lists:', err));

        // If editing, fetch the subscriber's details, including their selected lists
        if (id) {
            fetch(`/server/crm_function/api/subscribers/${id}`)
                .then(response => response.json())
                .then(subscriber => {
                    setEmail(subscriber.email);
                    setName(subscriber.name);
                    setStatus(subscriber.status);
                    setTags(subscriber.tags ? subscriber.tags.join(',') : '');

                    // Fetch the subscriber's lists
                    fetch(`/server/crm_function/api/subscribers/${id}/lists`)
                        .then(response => response.json())
                        .then(listData => {
                            const selectedListIds = listData.map(list => list.id);  // Extract list IDs
                            setListIds(selectedListIds);  // Set selected list IDs
                        })
                        .catch(err => console.error('Error fetching subscriber lists:', err));
                })
                .catch(err => console.error('Error fetching subscriber:', err));
        }
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Get user_id from localStorage
        const user = JSON.parse(localStorage.getItem('user'));

        if (!user || !user.id) {
            setError('User is not authenticated. Please log in.');  // Set error if user_id is missing
            return;  // Stop form submission
        }

        const userId = user.id;
        const subscriberData = {
            email,
            name,
            status,
            lists: listIds,  // Send selected list IDs
            tags: tags.split(',').map(tag => tag.trim()),  // Split tags into an array
            user_id: userId,
        };

        const method = id ? 'PUT' : 'POST';
        const endpoint = id
            ? `/server/crm_function/api/subscribers/${id}/edit`
            : '/server/crm_function/api/subscribers/create';

        fetch(endpoint, {
            method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(subscriberData),
        })
            .then(response => response.json())
            .then(() => {
                navigate('/app/subscribers');
            })
            .catch(err => console.error('Error saving subscriber:', err));
    };

    // Handle removing a selected list
    const handleRemoveSelectedList = (listId) => {
        setListIds(listIds.filter(id => id !== listId));  // Remove list from selected listIds
    };

    // Handle adding a list from the dropdown
    const handleAddList = (listId) => {
        if (!listIds.includes(listId)) {
            setListIds([...listIds, listId]);  // Add new list to selected listIds
        }
    };

    // Filter out the selected lists from the dropdown options
    const availableLists = allLists.filter(list => !listIds.includes(list.id));

    return (
        <div className="subscriber-form p-4">
            <h2>{id ? 'Edit Subscriber' : 'Create New Subscriber'}</h2>

            {/* Display error message if any */}
            {error && <Alert variant="danger">{error}</Alert>}

            <Form onSubmit={handleSubmit}>
                {/* Email */}
                <Form.Group controlId="subscriberEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter subscriber's email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Form.Group>

                {/* Name */}
                <Form.Group controlId="subscriberName" className="mt-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter subscriber's name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </Form.Group>

                {/* Status */}
                <Form.Group controlId="subscriberStatus" className="mt-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                        as="select"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                    >
                        <option value="enabled">Enabled</option>
                        <option value="disabled">Disabled</option>
                    </Form.Control>
                </Form.Group>

                {/* Selected Lists */}
                <Form.Group controlId="listSubscriptions" className="mt-3">
                    <Form.Label>Subscribed to Lists</Form.Label>
                    <div>
                        {listIds.map(listId => {
                            const selectedList = allLists.find(list => list.id === listId);
                            return (
                                <Button
                                    key={listId}
                                    variant="outline-primary"
                                    className="me-2 mb-2"
                                    onClick={() => handleRemoveSelectedList(listId)}
                                >
                                    {selectedList ? selectedList.name : 'Unknown List'} &times;
                                </Button>
                            );
                        })}
                    </div>
                </Form.Group>

                {/* Available Lists Dropdown */}
                <Form.Group controlId="availableLists" className="mt-3">
                    <Form.Label>Select Lists</Form.Label>
                    <Form.Control
                        as="select"
                        value=""  // Ensure it doesn't have a pre-selected option
                        onChange={(e) => handleAddList(Number(e.target.value))}
                    >
                        <option value="">Select list...</option>
                        {availableLists.map(list => (
                            <option key={list.id} value={list.id}>
                                {list.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>

                {/* Tags */}
                <Form.Group controlId="subscriberTags" className="mt-3">
                    <Form.Label>Tags</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter tags (comma-separated)"
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                    />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-3">
                    {id ? 'Update Subscriber' : 'Create Subscriber'}
                </Button>
            </Form>
        </div>
    );
};

export default SubscribersForm;
