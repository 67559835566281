import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const DashboardCards = ({
                            lists,
                            publicLists,
                            privateLists,
                            subscribers,
                            campaigns,
                            finishedCampaigns,
                            draftCampaigns,
                            messagesSent
                        }) => {
    return (
        <Row>
            <Col xs={12} md={6} lg={3}>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Lists</Card.Title>
                        <Card.Text>{lists} Lists</Card.Text>
                        <Card.Text>{publicLists} Public, {privateLists} Private</Card.Text>
                    </Card.Body>
                </Card>
            </Col>

            <Col xs={12} md={6} lg={3}>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Subscribers</Card.Title>
                        <Card.Text>{subscribers} Subscribers</Card.Text>
                    </Card.Body>
                </Card>
            </Col>

            <Col xs={12} md={6} lg={3}>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Campaigns</Card.Title>
                        <Card.Text>{campaigns} Campaigns</Card.Text>
                        <Card.Text>{finishedCampaigns} Finished, {draftCampaigns} Draft</Card.Text>
                    </Card.Body>
                </Card>
            </Col>

            <Col xs={12} md={6} lg={3}>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Messages Sent</Card.Title>
                        <Card.Text>{messagesSent} Messages sent</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default DashboardCards;
